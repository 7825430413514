import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
  createFilterOptions,
} from "@mui/material";
import {
  topUpAdminRequestAction,
  uploadFileRequestAction,
} from "redux/actions/usersActions";
import { useDispatch, useSelector } from "react-redux";
import Autocomplete from "components/Autocomplete";
import DropDown from "components/DropDown";
import { showMessage } from "components/Modal";
import AppNumberInput from "components/AppNumberInput";
import {
  removeBalanceSeparator,
  toFixedWithoutZeros,
} from "constants/usefulFunctions";
import { LoadingButton } from "@mui/lab";
import axios from "axios";
import { API_GET_SEARCH_ADMIN, token } from "constants/constants";
import debounce from "lodash/debounce";
import CustomFileUploader from "components/CustomFileUploader";
import {
  calculateAdminFeeRequestAction,
  calculateFeeRequestAction,
} from "redux/actions/GeneralActions";
import CloseIcon from "@mui/icons-material/Close";
import {
  getAdminWallet,
  getSuperAdminBalanceAction,
} from "redux/actions/adminActions";
import { superAdmin } from "constant";
// --------------------------------------------------------------------------------

const TopUpAdmin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [desc, setDesc] = useState("");
  const [amount, setAmount] = useState("0.0");
  const [commission, setCommission] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [options, setOptions] = useState([]);
  const [selectedAdmin, setSelectedAdmin] = useState({});
  const [inputValue, setInputValue] = useState("");
  const [file, setFile] = useState(null);
  const [adminWallets, setAdminWallets] = useState([]);
  const [adminWallet, setAdminWallet] = useState("");
  const [adminLimits, setAdminLimits] = useState("");
  const [showConfirm, setShowConfirm] = useState(false);
  const [adminBalance, setAdminBalance] = useState();
  const [balanceChanged, setBalanceChanged] = useState(false);
  const [minBalance, setMinBalance] = useState(0.0);

  const roles = useSelector((state) => state.auth.user.roles);

  const topUpFee = (
    parseFloat(adminLimits.adminFee) +
    (parseFloat(adminLimits.adminPercentFee) / 100) * amount
  ).toFixed(2);

  const authToken = useSelector((state) => {
    return state.auth.token;
  });

  useEffect(() => {
    if (inputValue.length > 3) requestAdminOptions();
  }, [inputValue]);

  useEffect(() => {
    if ("id" in selectedAdmin && adminWallet) {
      dispatch(
        calculateAdminFeeRequestAction({
          eventId: 1,
          adminId: selectedAdmin.id,
          walletId: adminWallet,
          setAdminLimits,
        })
      );
      dispatch(
        getSuperAdminBalanceAction(adminWallet, setAdminBalance, setLoading2)
      );
    }
  }, [selectedAdmin, adminWallet]);

  useEffect(() => {
    if (adminWallets.length > 0) {
      const adminWallet = adminWallets.find((wallet) => wallet.id === wallet);
      if (adminWallet) {
        setMinBalance(adminWallet.minimumBalance);
      }
    }
  }, [adminWallet]);

  const handleInputChange = debounce((newValue) => {
    setInputValue(newValue);
  }, 400);

  const requestAdminOptions = () => {
    if (!loading) setLoading(true);
    axios
      .get(`${API_GET_SEARCH_ADMIN}?keyword=${encodeURIComponent(inputValue)}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then((res) => {
        setLoading(false);
        setOptions(res.data.data);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching data:", error);
      });
  };

  const filterOptions = createFilterOptions({
    stringify: (option) =>
      `${option.firstName} ${option.lastName}` +
      option.email +
      option.mobile?.toString() +
      option.iban?.toString() +
      option.website_domain +
      option?.channel_name,
  });

  const handleClose = () => {
    setShowConfirm(false);
  };

  const handleAmountChange = (e) => {
    setAmount(e.target.value);
    setBalanceChanged(true);
  };

  const getErrorMessage = () => {
    if (!balanceChanged) return null;
    switch (true) {
      case amount - topUpFee <= 0:
        return "Invalid Charged Amount";
      // case amount < +adminLimits.min:
      //   return `Please enter an amount greater than ${adminLimits.min}${adminLimits.symbol}.`;
      // case amount > +adminLimits.max:
      //   return `Please enter an amount less than or equal to ${adminLimits.max}${adminLimits.symbol}.`;
      // case ((amount - topUpFee) - minBalance) > +adminLimits.max || ((amount - topUpFee) - minBalance) < +adminLimits.min:
      //   return `The charged amount is out of the acceptable limits!`;
      // case amount <= adminBalance:
      //   return "admin does not have enough balance"
      default:
        return null;
    }
  };

  const errorMessage = getErrorMessage();
  const hasError = errorMessage !== null;

  const renderDialogConfirm = (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={showConfirm}
    >
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        Top-up Agent Wallet
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <Typography gutterBottom>
          Admin's Full Name:{" "}
          <Typography sx={{ fontWeight: 500, display: "inline-block" }}>
            {selectedAdmin.firstName} {selectedAdmin.lastName}
          </Typography>
        </Typography>
        <Typography gutterBottom>
          Email:{" "}
          <Typography sx={{ fontWeight: 500, display: "inline-block" }}>
            {selectedAdmin.email}
          </Typography>{" "}
        </Typography>
        <Typography gutterBottom>
          Amount:{" "}
          <Typography sx={{ fontWeight: 500, display: "inline-block" }}>
            {toFixedWithoutZeros(amount)}
            {adminLimits.symbol}
          </Typography>
        </Typography>
        <Typography gutterBottom>
          Fee:{" "}
          <Typography sx={{ fontWeight: 500, display: "inline-block" }}>
            {toFixedWithoutZeros(topUpFee)}
            {adminLimits.symbol}
          </Typography>
        </Typography>
        <Typography gutterBottom>
          Charged Amount:{" "}
          <Typography sx={{ fontWeight: 500, display: "inline-block" }}>
            {toFixedWithoutZeros(amount - topUpFee)}
            {/* {toFixedWithoutZeros(amount )} */}
            {adminLimits.symbol}
          </Typography>
        </Typography>
        <Typography gutterBottom>
          Description:{" "}
          <Typography sx={{ fontWeight: 500, display: "inline-block" }}>
            {desc}
          </Typography>
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={handleClose}>
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          sx={{ boxShadow: "none" }}
          onClick={() =>
            dispatch(
              topUpAdminRequestAction({
                adminId: selectedAdmin.id,
                walletId: adminWallet,
                amount: removeBalanceSeparator(amount),
                description: desc,
                file,
                setLoading,
                onSuccess: () =>
                  showMessage({
                    status: "success",
                    onSubmit: () => {
                      navigate("/top-up-agent/list");
                    },
                  }),
              })
            )
          }
          loading={loading}
        >
          Submit
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );

  return (
    <Card>
      <CardHeader title="Top-up Agent Wallet" />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4} lg={3}>
            <div className="searchbox right-side-icon bg-transparent d-block">
              <Autocomplete
                id="combo-box-demo"
                fullWidth
                options={options}
                getOptionLabel={(option) => (option.email ? option.email : "")}
                loading={loading}
                filterOptions={filterOptions}
                renderOption={(e, option) => (
                  <div
                    key={option.id}
                    onClick={() => {
                      setSelectedAdmin(option);
                      dispatch(
                        getAdminWallet({
                          adminId: option.id,
                          setData: setAdminWallets,
                        })
                      );
                    }}
                    {...e}
                    className={`${e.className} option-container`}
                  >
                    <div>
                      <span>Name: </span>
                      {`${option.firstName} ${option.lastName}`}
                    </div>
                    <div>
                      <span>Email:</span> {option.email}
                    </div>
                  </div>
                )}
                onInputChange={(_, newValue) => {
                  handleInputChange(newValue);
                }}
                onChange={(_, newValue) => {
                  setSelectedAdmin(newValue);
                  dispatch(
                    getAdminWallet({
                      adminId: newValue.id,
                      setData: setAdminWallets,
                    })
                  );
                  setLoading(false);
                }}
                value={selectedAdmin}
                disablePortal
                clearOnEscape
                autoHighlight
                blurOnSelect
                selectOnFocus
                handleHomeEndKeys
                onBlur={() => {
                  setInputValue("");
                  setLoading(false);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Admin"
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <div
                          style={{
                            backgroundColor: "white",
                            height: 36,
                            alignItems: "center",
                            display: "flex",
                          }}
                        >
                          {loading && (
                            <CircularProgress color="primary" size={30} />
                          )}
                          {params.InputProps.endAdornment}
                        </div>
                      ),
                    }}
                  />
                )}
              />
            </div>
          </Grid>
          <Grid item xs={12} md={4} lg={3}>
            <DropDown
              label="Wallet"
              schema={{ label: "currency", value: "id" }}
              items={adminWallets}
              onChange={(event) => setAdminWallet(event.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={3}>
            <AppNumberInput
              thousandSeparator
              label="Amount"
              onChange={handleAmountChange}
              value={amount}
              fullWidth
              decimalScale={2}
              error={hasError}
              helperText={hasError ? errorMessage : ""}
              maxLength={19}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={3}>
            <TextField
              label="Description"
              onChange={(e) => setDesc(e.target.value)}
              value={desc}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={4} lg={3}>
            <CustomFileUploader
              onChange={({ event, setUploadPercent, onSuccess }) => {
                const file = event.target.files[0];
                const formData = new FormData();
                formData.append("topup_receipt", file);
                dispatch(
                  uploadFileRequestAction({
                    formData,
                    setUploadPercent,
                    onSuccess: (data) => {
                      onSuccess();
                      setFile(data);
                    },
                  })
                );
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ visibility: adminLimits ? "visible" : "hidden" }}
          >
            <Typography sx={{ display: "inline" }}>Admin Balance: </Typography>
            <Typography sx={{ display: "inline", fontWeight: 600 }}>
              {toFixedWithoutZeros(adminBalance)} {adminLimits.symbol}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions sx={{ justifyContent: "center" }}>
        <LoadingButton
          variant="contained"
          onClick={() => setShowConfirm(true)}
          disabled={
            !selectedAdmin.id ||
            !adminWallet ||
            desc.length === 0 ||
            // Number(amount) >= adminBalance
            amount - topUpFee <= 0
          }
          loading={loading2}
        >
          Submit
        </LoadingButton>
      </CardActions>
      {renderDialogConfirm}
    </Card>
  );
};

export default TopUpAdmin;
