export const API_BASE_URL = `${process.env.REACT_APP_API_URL}/api`;

export const GET_PHYSICAL_CARDS = "cards/list/physical";
export const GET_VIRTUAL_CARDS = "cards/list/virtual";
export const GET_ALL_USERS = "users/list";
export const GET_PENDING_USERS = "users/list/pending";
export const GET_APPROVED_USERS = "users/list/approved";
export const GET_STARTER_USERS = "users/list/starter";
export const GET_PREMIUM_USERS = "users/list/premium";
export const GET_DECLINED_USERS = "users/list/declined";
export const GET_TOPUP_LIST = "topup/list";
export const GET_TOPUP_AGENT_LIST = "topup/agent/list";
export const GET_TOPUP_CARD_LIST = "topup/card/list";
export const GET_TOPUP_IBAN_LIST = "topup/iban-list";
export const GET_AGENT_TRANSACTIONS = "user/transactions";
export const GET_AGENT_TOPUP_LIST = "topup/agent/list";

export const GET_PEP_LOG_LIST = "log/pep/list";
export const GET_ADMIN_ACTIVITY_LOG_LIST = "log/activity/list";
export const GET_DEASHBOARD_ACTIVITY_LOG_LIST = "log/dashboard/activity/list";

export const GET_CARD_TRANSACTIONS = "card/transactions";
export const GET_USER_TRANSACTIONS = "user/transactions";

export const GET_AGENT_TRANSACTION_LIST = "agent/transaction/list"
