import React, { useEffect, useMemo, useRef, useState } from "react";
import { Typography } from "@mui/material";
import { errorColor, successColor } from "assets/colors";
import PaginationTable from "components/PaginationTable";
import { GET_AGENT_TRANSACTION_LIST } from "config/apiRoutes";
// --------------------------------------------------------------------------------
const AgentTransactions = () => {

  const [isInitialLoad, setIsInitialLoad] = useState(true);
   const paginationTableRef = useRef();

   useEffect(() => {
       !isInitialLoad
         ? paginationTableRef.current.refetch()
         : setIsInitialLoad(false);
     }, []);

  const columns = useMemo(
    () => [
      {
        accessorKey: "event",
        header: "Event",
      },
      {
        accessorKey: "amount",
        header: "Amount",
        Cell: ({ row }) => {
          let amount = row.original.amount;
          let symbol = row.original.type === "increase" ? "+" : "-";
          let color =
            row.original.type === "increase" ? successColor : errorColor;
          return <Typography sx={{ color }}>{`${symbol}${amount}`}</Typography>;
        },
      },
      {
        accessorKey: "status",
        header: "Status",
      },
      {
        accessorKey: "wallet",
        header: "Wallet",
      },
    ],
    []
  );

  return (

    <PaginationTable
    ref={paginationTableRef}
    title="Transactions"
    apiEndpoint={GET_AGENT_TRANSACTION_LIST}
    columns={columns}
  />
  );
};

export default AgentTransactions;
